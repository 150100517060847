export const actionTypes = {
  VALIDATEUSER_SUCCESS: 'VALIDATEUSER_SUCCESS',
  VALIDATEUSER_FAILURE: 'VALIDATEUSER_FAILURE',
  LOGOFF: 'LOGOFF',
  LOGIN_LOADER: 'LOGIN_LOADER',

  GET_SLOTS_SUCCESS: 'GET_SLOTS_SUCCESS',
  GET_SLOTS_LOADER: 'GET_SLOTS_LOADER',
  GET_SLOTS_FAILURE: 'GET_SLOTS_FAILURE',

  GET_TIMEZONES_SUCCESS: 'GET_TIMEZONES_SUCCESS',
  GET_TIMEZONES_FAILURE: 'GET_TIMEZONES_FAILURE',
  GET_TIMEZONES_LOADER: 'GET_TIMEZONES_LOADER',

  SUBMIT_SLOTS_SUCCESS: 'SUBMIT_SLOTS_SUCCESS',
  SUBMIT_SLOTS_LOADER: 'SUBMIT_SLOTS_LOADER',
  SUBMIT_SLOTS_FAILURE: 'SUBMIT_SLOTS_FAILURE',
}
