import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import background from '../../assets/background.jpeg'
import logo from '../../images/logo.png'
import logoLight from '../../images/logoLight.png'
import BGDark from '../../images/BG.jpg'
import BGLite from '../../images/BGLight.png'
import { useDispatch, useSelector } from 'react-redux'
import { LoginAction } from '../../Actions/LoginAction'
import LoadingButton from '@mui/lab/LoadingButton'
import { Paper } from '@mui/material'

let loginBackGround = {
  // width: '100%',
}

let boxBackground = {
  // background: '#272528',
}

let loginStyle = {
  display: 'flex',
  marginLeft: 'auto',
}

let loginWrapper = {
  height: '50vh',
}

const Login = ({ matches, mode }) => {
  let dispatch = useDispatch()
  const loginLoader =
    useSelector((state) => state.LoginReducer.loginLoader) || false

  let [userDetails, setUserDetails] = useState({
    EmailID: '',
    PassCode: '',
  })

  const updateUserDetails = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const validateUser = () => {
    // console.log(userDetails);
    dispatch(LoginAction.validateUser(userDetails))
    dispatch(LoginAction.loginLoader())
  }

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(80,80,80,.4), rgba(80,80,80,.4)), url(${
          mode === 'light' ? BGLite : BGDark
        })`,
        backgroundPosition: 'unset',
        backgroundSize: 'cover',
        padding: '20px',
        height:'90vh'
      }}
    >
      <Grid container justifyContent={matches ? 'center' : 'flex-start'}>
        {!matches && <Grid item xs={0} md={0} lg={7}></Grid>}
        <Grid item xs={matches ? 6 : 2} md={matches ? 6 : 4} alignSelf="center">
          <Box mt={3} mb={2}>
            <img
              src={mode === 'light' ? logoLight : logo}
              style={{ width: matches ? '150px' : '300px' }}
              alt="company logo"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={matches ? 'center' : 'space-around'}
        alignItems="center"
        style={loginWrapper}
      >
        {!matches && (
          <Grid item sm={0} md={3}>
            <Box mt={4}> &nbsp;</Box>
          </Grid>
        )}

        <Grid item xs={10} md={3} alignSelf="center">
          <Paper>
            <Box p={4} boxShadow={2} style={boxBackground}>
              <Typography variant="h5">Login</Typography>
              <Box mb={1}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  onChange={updateUserDetails}
                  name="EmailID"
                  value={userDetails.email}
                  variant="standard"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  type="password"
                  fullWidth
                  id="password"
                  label="Password"
                  onChange={updateUserDetails}
                  name="PassCode"
                  value={userDetails.password}
                  variant="standard"
                />
              </Box>

              <LoadingButton
                style={loginStyle}
                disabled={loginLoader}
                color="primary"
                onClick={validateUser}
                loading={loginLoader}
                variant="contained"
              >
                Login
              </LoadingButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
