import { endpoints } from '../config/endpoints'
import request from '../config/axios'

/**
 * Functions used to issue AJAX requests and manage responses.
 * All of the included methods use the Axios library for Promise-based requests.
 */
export const LoginUserService = {
  validateUser,
  getSlots,
  getTimezones
}

function validateUser(params) {
  let finalPath = endpoints.MAINURL + endpoints.VALIDATEAPPLICANT

  return request({
    method: 'post',
    url: finalPath,
    data: params,
  }).then((resp) => {
    return resp.data
  })
}

function getSlots(params) {
  let finalPath = endpoints.MAINURL + endpoints.APPLICANTAVAILABLESLOTS

  return request({
    method: 'post',
    url: finalPath,
    data: params,
  }).then((resp) => {
    return resp.data
  })
}


function getTimezones(params) {
  let finalPath = endpoints.MAINURL + endpoints.APPLICANTAVAILABLESLOTS

  return request({
    method: 'get',
    url: finalPath,
    data: params,
  }).then((resp) => {
    return resp.data
  })
}
