import { actionTypes } from '../Actions/actionTypes/LoginActionTypes'

let initialState = {
  isUserValid: false,
  userName: '',
  loginLoader: false,
  CaseID: '',
  getSlotsLoader: false,
  DisplaySlots: [],
  submitSlotsLoader: false,
  isFinalScreenSubmited: false,
  getTimezonesLoader: false,
  timeZones: [],
}
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.VALIDATEUSER_SUCCESS:
      return {
        ...state,
        isUserValid: action.cases.IsValidApplicant === 'true' ? true : false,
        Reschedule: action.cases.Reschedule === 'true' ? true : false,
        userName: action.cases.FirstName + ' ' + action.cases.LastName,
        loginLoader: false,
        CaseID: action?.cases?.ApplicantDetails?.ApplicantID || '',
        // CaseID: 'APL-38221',
      }

    case actionTypes.VALIDATEUSER_FAILURE:
      return {
        ...state,
        isUserValid: false,
        loginLoader: false,
        userName: '',
      }
    case actionTypes.LOGOFF:
      return {
        ...state,
        isUserValid: false,
        isFinalScreenSubmited: false,
        userName: '',
      }
    case actionTypes.LOGIN_LOADER:
      return {
        ...state,
        loginLoader: true,
      }

    case actionTypes.GET_SLOTS_SUCCESS:
      return {
        ...state,
        getSlotsLoader: false,
        DisplaySlots: action?.cases?.DisplaySlots
          ? action?.cases?.DisplaySlots
          : [],
      }
    case actionTypes.GET_SLOTS_FAILURE:
      return {
        ...state,
        getSlotsLoader: false,
      }
    case actionTypes.GET_SLOTS_LOADER:
      return {
        ...state,
        getSlotsLoader: true,
      }
    case actionTypes.SUBMIT_SLOTS_SUCCESS:
      return {
        ...state,
        submitSlotsLoader: false,
        isFinalScreenSubmited:
          action?.cases?.pyLabel === 'Error' ? false : true,
      }
    case actionTypes.SUBMIT_SLOTS_FAILURE:
      return {
        ...state,
        submitSlotsLoader: false,
        isFinalScreenSubmited: false,
      }
    case actionTypes.SUBMIT_SLOTS_LOADER:
      return {
        ...state,
        submitSlotsLoader: true,
      }
    case actionTypes.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timeZones: action?.cases?.AvailableTimeZone,
        getTimezonesLoader: false,
      }
    case actionTypes.GET_TIMEZONES_FAILURE:
      return {
        ...state,
        getTimezonesLoader: false,
        timeZones: [],
      }
    case actionTypes.GET_TIMEZONES_LOADER:
      return {
        ...state,
        getTimezonesLoader: true,
      }

    default:
      return state
  }
}
