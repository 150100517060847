import { actionTypes } from './actionTypes/LoginActionTypes'

import { LoginUserService } from '../services/LoginService'
import { noty } from '../services/notyService'

export const LoginAction = {
  validateUser,
  loginLoader,
  logOff,
  getSlots,
  getSlotsLoader,
  submitSlotsLoader,
  submitSlots,
  getTimezones,
  getTimezonesLoader,
}

function validateUser(params) {
  return (dispatch) => {
    LoginUserService.validateUser(params).then(
      (cases) => {
        dispatch(success(cases))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function success(cases) {
    if (cases.pyNote) {
      noty(cases.Status, cases.pyNote)
    }
    localStorage.setItem(
      'isUserlogged',
      cases.IsValidApplicant === 'true' ? true : false,
    )

    localStorage.setItem('userName', cases.FirstName + ' ' + cases.LastName)
    localStorage.setItem(
      'Reschedule',
      cases.Reschedule === 'true' ? true : false,
    )

    localStorage.setItem(
      'CaseID',
      cases.ApplicantDetails?.ApplicantID
        ? cases.ApplicantDetails?.ApplicantID
        : '',
    )

    return { type: actionTypes.VALIDATEUSER_SUCCESS, cases }
  }
  function failure(error) {
    return { type: actionTypes.VALIDATEUSER_FAILURE, error }
  }
}

function logOff() {
  return (dispatch) => {
    dispatch(success())
  }

  function success() {
    return { type: actionTypes.LOGOFF }
  }
}
function loginLoader() {
  return (dispatch) => {
    dispatch(success())
  }

  function success() {
    return { type: actionTypes.LOGIN_LOADER }
  }
}

function getSlotsLoader() {
  return (dispatch) => {
    dispatch(success())
  }

  function success() {
    return { type: actionTypes.GET_SLOTS_LOADER }
  }
}

function getSlots(params) {
  return (dispatch) => {
    LoginUserService.getSlots(params).then(
      (cases) => {
        dispatch(success(cases))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function success(cases) {
    if (cases.pyNote) {
      noty(cases.Status, cases.pyNote)
    }

    return { type: actionTypes.GET_SLOTS_SUCCESS, cases }
  }
  function failure(error) {
    return { type: actionTypes.GET_SLOTS_FAILURE, error }
  }
}

function submitSlotsLoader() {
  return (dispatch) => {
    dispatch(success())
  }

  function success() {
    return { type: actionTypes.SUBMIT_SLOTS_LOADER }
  }
}

function submitSlots(params) {
  return (dispatch) => {
    LoginUserService.getSlots(params).then(
      (cases) => {
        dispatch(success(cases))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function success(cases) {
    if (cases.pyNote) {
      noty(cases.Status, cases.pyNote)
    }

    return { type: actionTypes.SUBMIT_SLOTS_SUCCESS, cases }
  }
  function failure(error) {
    return { type: actionTypes.SUBMIT_SLOTS_FAILURE, error }
  }
}

function getTimezones(params) {
  return (dispatch) => {
    LoginUserService.getTimezones(params).then(
      (cases) => {
        dispatch(success(cases))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function success(cases) {
    if (cases.pyNote) {
      noty(cases.Status, cases.pyNote)
    }

    return { type: actionTypes.GET_TIMEZONES_SUCCESS, cases }
  }
  function failure(error) {
    return { type: actionTypes.GET_TIMEZONES_FAILURE, error }
  }
}

function getTimezonesLoader() {
  return (dispatch) => {
    dispatch(success())
  }

  function success() {
    return { type: actionTypes.GET_TIMEZONES_LOADER }
  }
}
